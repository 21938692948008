@media all and (max-width: 980px) {
  .axis {
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  #header {
    padding: 10px 0;
    position: relative;
  }
  #header .nav {
    padding: 0;
    margin: 0;
    position: fixed;
    top: 0;
    width: 100%;
    left: 100%;
    float: none;
    right: 0;
    z-index: 900;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    background: #91ba18;
    white-space: nowrap;
    max-height: 100%;
    overflow: auto;
  }
  #header .search {
    display: none;
  }
  #header .mainMenu {
    margin: 0;
    float: none;
    width: 100%;
    height: auto;
    padding-top: 5px;
    overflow: visible;
  }
  #header .mainMenu ul {
    overflow: auto;
  }
  #header .mainMenu ul > li {
    display: block;
    float: none;
    margin: 0;
    border: none;
    border-bottom: 1px solid #fff;
    text-align: left;
    padding: 0 !important;
    height: auto;
    line-height: 1.2;
  }
  #header .mainMenu ul > li > a {
    display: block;
    padding: 17px;
    color: #fff;
    font-weight: bold;
    height: auto;
    line-height: 1.2;
    font-size: 14px;
    font-size: 1.4rem;
  }
  #header .mainMenu ul > li > a:hover {
    color: #fff;
  }
  #header .mainMenu ul > li > a:after {
    display: none;
  }
  #header .mainMenu ul ul {
    display: none !important;
  }
  #header .mainMenu ul .subMenu {
    display: none;
  }
  #header .menuChanger {
    position: fixed;
    z-index: 999;
    left: 100%;
    margin-left: -56px;
    top: 0;
    display: block !important;
    width: 56px;
    height: 56px;
    background: #91ba18;
    overflow: hidden;
    -webkit-transform: translateZ(0);
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
  }
  #header .menuChanger:before, #header .menuChanger:after {
    font-family: FontAwesome;
    display: block;
    color: #fff;
    font-size: 24px;
    line-height: 56px;
    text-align: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
  }
  #header .menuChanger:before {
    content: '\f0c9';
    right: 0;
  }
  #header .menuChanger:after {
    content: '\f00d';
    left: 100%;
  }
  #header.showMenu .nav {
    left: 0;
  }
  #header.showMenu .menuChanger {
    background: #5c760f;
  }
  #header.showMenu .menuChanger:before {
    right: 100%;
  }
  #header.showMenu .menuChanger:after {
    left: 0;
  }
  #main {
    margin-top: 0;
  }
  .banners {
    height: auto;
  }
  .banners .bannersList,
  .banners .categoriesInLine {
    padding-left: 0;
    padding-right: 0;
  }
  .banners .banner > img {
    width: auto;
  }
  .banners .banner .textContent {
    left: 10px;
  }
  .layoutBanners .pager {
    display: none;
  }
  .layoutBanners .arrows {
    left: 386px;
  }
  .layoutBanners .arrows > div {
    width: 40px;
    height: 40px;
    line-height: 38px;
  }
  .layoutBanners .arrows > div:before {
    font-size: 20px;
  }
  .mainBlocks .head .img {
    width: auto;
    float: none;
    margin-bottom: 5px;
  }
  .mainBlocks .head .img img {
    position: relative;
  }
  .mainBlocks .textContent {
    min-height: 0;
  }
  .footerBlocks .contactInfo {
    display: none;
  }
  .footerBlocks .newsletter {
    width: auto;
    float: none;
  }
  .footerFinish .slogan {
    display: none;
  }
  .footerFinish .map {
    float: none;
  }
  .imageInfo .infoLabel {
    right: -10px;
  }
  .aboutInfo {
    max-width: none;
    border-bottom: 0;
  }
  .aboutInfo .info {
    margin-top: 30px;
  }
  .aboutPage .moreInfo {
    padding-left: 0;
  }
  .terraGroup .groupsList {
    padding-left: 0;
  }
  .terraGroup .groupsList:before {
    display: none;
  }
  .terraGroup .item img {
    width: 200%;
    left: -100%;
  }
  .colorsList.count_6 .colorItem {
    width: 20%;
  }
  .categoriesBanners {
    height: 454px;
  }
  .categoriesBanners > .axis {
    padding-left: 0;
    padding-right: 0;
  }
  .categoriesView .collectionHead .left {
    display: none;
  }
  .categoriesView .collectionHead .right {
    float: none;
    width: auto;
  }
  .textContent img {
    max-width: 100%;
    height: auto;
  }
  .productsList .img {
    height: auto;
  }
  .catProdList {
    margin-left: 0;
    margin-right: 0;
  }
  .catProdList .categoryItem:before {
    left: -20px;
    right: -20px;
  }
  .catProdList .catName {
    margin-left: 0;
    margin-right: 0;
  }
  .productViewItem .productInfo,
  .productViewItem .productOtherInfo {
    width: auto;
    float: none;
  }
  .productViewItem .productOtherInfo {
    position: relative;
  }
  .productViewItem .productOtherInfo .productDownloads {
    position: absolute;
    right: 0;
  }
  .productViewItem .productOtherInfo .fullImg {
    margin: 0;
  }
  .productViewItem .productOtherInfo .buyIt {
    padding-left: 0;
  }
  .searchForm .advanced {
    margin-left: 0;
    margin-right: 0;
  }
  .searchResults {
    padding-left: 0;
    padding-right: 0;
  }
  .newsContent img {
    max-width: 100%;
    height: auto;
  }
  .newsContent .newsPageText {
    max-width: 200px;
  }
}

@media all and (max-width: 719px) {
  .categoriesInLine {
    background: #91ba18;
  }
  .categoriesInLine.count_7 a, .categoriesInLine.count_8 a, .categoriesInLine.count_9 a, .categoriesInLine.count_10 a {
    width: 25%;
    border-bottom: 1px solid #bde644;
  }
  .categoriesInLine.count_7 a:last-child, .categoriesInLine.count_8 a:last-child, .categoriesInLine.count_9 a:last-child, .categoriesInLine.count_10 a:last-child {
    border-right: 1px solid #bde644;
  }
  .inRow.count_4 .item {
    width: 49%;
  }
  .mainLinks .inRow .item {
    width: 50%;
    margin-bottom: 30px;
  }
  .colorsList.count_6 .colorItem {
    width: 25%;
  }
  .contactCatalogue {
    max-width: none;
    margin-left: 0;
  }
  .categoriesBanners .categoriesVList {
    width: 200px;
  }
  .searchForm .aRow.count_4 .item {
    width: 50%;
  }
  .newsContent .newsPageText {
    display: none;
  }
}

@media all and (max-width: 600px) {
  .axis {
    padding-left: 10px;
    padding-right: 10px;
  }
  .newsSimpleItem .newsItem .img {
    width: auto;
    float: none;
    margin: 0 0 10px;
  }
  .footerBlocks .contactData {
    margin-right: 0;
    min-height: 0;
  }
  .footerBlocks .catalogue {
    position: static;
    margin-top: 20px;
  }
  .footerFinish {
    margin-top: 20px;
  }
  .footerFinish .map .name {
    float: none;
    margin-bottom: 5px;
  }
  .imageInfo .img {
    display: none;
  }
  .terraGroup .item {
    width: 33.33333% !important;
    margin-bottom: 20px;
  }
  .materialsIndex .materialsList {
    margin: 0;
    width: auto;
  }
  .materialsList .img {
    margin-right: 15px;
  }
  table.rwdTable {
    display: block;
  }
  table.rwdTable > tr, table.rwdTable > tbody > tr {
    display: block;
  }
  table.rwdTable > tr > td, table.rwdTable > tr > th, table.rwdTable > tbody > tr > td, table.rwdTable > tbody > tr > th {
    margin-bottom: 20px;
    display: block;
    width: auto !important;
    padding: 0;
    overflow: hidden;
  }
  .rwdHide {
    display: none !important;
  }
  .contactPage .info,
  .contactPage .form {
    width: auto;
    float: none;
  }
  .contactPage .form {
    margin-top: 40px;
  }
  .categoriesView .cRow .left,
  .categoriesView .cRow .right {
    float: none;
    width: auto;
    padding: 0;
  }
  .categoriesView .collectionHead {
    display: none;
  }
  .brandsInfoList .item {
    width: 100% !important;
  }
  .brandsInfoList .item.hasImage {
    padding-bottom: 0 !important;
  }
  .brandsInfoList .item .img {
    position: static;
  }
  .productsList.count_5 .prodItem {
    width: 25%;
  }
  .categoriesBanners .bannerName {
    top: 5px;
  }
  .categoriesBanners .bannerName span {
    float: none;
    margin-bottom: 3px;
    text-align: right;
  }
  .categoriesBanners .bannerName .pName {
    width: auto;
  }
  .productHead .brand {
    float: none;
    width: auto;
    border-bottom: 1px solid #91ba18;
    text-align: center;
  }
  .productBottomInfo .textInfo,
  .productBottomInfo .specificationInfo {
    width: auto;
    float: none;
  }
  .productBottomInfo .smallColors.count_3 .item {
    width: 20%;
  }
  .searchForm .aRow.count_3 .item {
    width: 100%;
  }
  .searchForm .aRow.count_3 .subTitle {
    margin-top: 20px;
  }
  .searchForm .finishRow .tags {
    float: none;
  }
  .searchForm .finishRow .niceInputs .input {
    float: none;
    margin: 0 0 8px;
  }
  .searchForm .submit {
    margin-top: 20px;
  }
  .searchForm .submit .button {
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
  }
}

@media all and (max-width: 479px) {
  .categoriesInLine.count_7 a, .categoriesInLine.count_8 a, .categoriesInLine.count_9 a, .categoriesInLine.count_10 a {
    width: 50%;
  }
  .banners .banner .textContent {
    width: auto;
    right: 10px;
  }
  .layoutBanners .arrows {
    width: auto;
    top: 245px;
    left: 10px;
  }
  .inRow .item {
    width: 100% !important;
  }
  .terraGroup .item {
    width: 50% !important;
  }
  .colorsList.count_6 .colorItem {
    width: 33.33333%;
  }
  .contactCatalogue .form label {
    width: auto;
    float: none;
    margin-right: 0;
    text-align: left;
  }
  .categoriesBanners .img img {
    display: block;
    position: relative;
    left: -90px;
  }
  .productsList.count_5 .prodItem {
    width: 33.33333%;
  }
  .productBottomInfo .smallColors.count_3 .item {
    width: 25%;
  }
  .changeProduct .prev,
  .changeProduct .next {
    width: 50px;
  }
  .productViewItem .productOtherInfo .productDownloads {
    position: relative;
    margin-top: 0;
  }
  .searchForm .aRow.count_4 .item {
    width: 100%;
  }
  .searchForm .aRow.count_4 .subTitle {
    margin-top: 20px;
  }
}

@media all and (max-width: 360px) {
  .colorsList.count_6 .colorItem {
    width: 50%;
  }
  .materialsList .img {
    float: none;
    margin: 0 0 10px;
  }
  .categoriesBanners .categoriesVList {
    float: none;
    width: auto;
  }
  .categoriesBanners .img {
    display: none;
  }
  .productsList.count_5 .prodItem {
    width: 50%;
  }
  .productBottomInfo .smallColors.count_3 .item {
    width: 33.33333%;
  }
}
