@charset "UTF-8";

@import "_variables";

@media print,
(-o-min-device-pixel-ratio: 5/4),
(-webkit-min-device-pixel-ratio: 1.25),
(min-resolution: 120dpi) {

}

@media all and (max-width: $width-axis + 20px) {
	.axis {
		width: auto;
		padding-left: 15px;
		padding-right: 15px;
	}

	#header {
		padding: 10px 0;
		position: relative;

		.nav {
			padding: 0;
			margin: 0;
			position: fixed;
			top: 0;
			width: 100%;
			left: 100%;
			float: none;
			right: 0;
			z-index: 900;
			@include prefix(transition, all 500ms ease);
			background: $color-base;
			white-space: nowrap;
			max-height: 100%;
			overflow: auto;
		}

		.search {
			display: none;
		}

		.mainMenu {
			margin: 0;
			float: none;
			width: 100%;
			height: auto;
			padding-top: 5px;
			overflow: visible;

			ul {
				overflow: auto;

				> li {
					display: block;
					float: none;
					margin: 0;
					border: none;
					border-bottom: 1px solid #fff;
					text-align: left;
					padding: 0 !important;
					height: auto;
					line-height: 1.2;

					> a {
						display: block;
						padding: 17px;
						color: #fff;
						font-weight: bold;
						height: auto;
						line-height: 1.2;
						@include rem(font-size, 1.4rem);

						&:hover {
							color: #fff;
						}

						&:after {
							display: none;
						}
					}
				}

				ul {
					display: none !important;
				}

				.subMenu {
					display: none;
				}
			}
		}

		.menuChanger {
			$size: 56px;
			position: fixed;
			z-index: 999;
			left: 100%;
			margin-left: -$size;
			top: 0;
			display: block !important;
			width: $size;
			height: $size;
			background: $color-base;
			overflow: hidden;
			-webkit-transform: translateZ(0);
			@include transition();

			&:before,
			&:after {
				font-family: FontAwesome;
				display: block;
				color: #fff;
				font-size: 24px;
				line-height: $size;
				text-align: center;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				@include prefix(transition, all 500ms ease);
			}

			&:before {
				content: '\f0c9';
				right: 0;
			}

			&:after {
				content: '\f00d';
				left: 100%;
			}
		}

		&.showMenu {
			.nav {
				left: 0;
			}

			.menuChanger {
				background: darken($color-base, 15%);

				&:before {
					right: 100%;
				}

				&:after {
					left: 0;
				}
			}
		}
	}

	#main {
		margin-top: 0;
	}

	.banners {
		height: auto;

		.bannersList,
		.categoriesInLine {
			padding-left: 0;
			padding-right: 0;
		}

		.banner {
			> img {
				width: auto;
			}

			.textContent {
				left: 10px;
			}
		}
	}

	.layoutBanners {
		.pager {
			display: none;
		}

		.arrows {
			left: 386px;

			> div {
				width: 40px;
				height: 40px;
				line-height: 38px;

				&:before {
					font-size: 20px;
				}
			}
		}
	}

	.mainBlocks {
		.head {
			.img {
				width: auto;
				float: none;
				margin-bottom: 5px;

				img {
					position: relative;
				}
			}
		}

		.textContent {
			min-height: 0;
		}
	}

	.footerBlocks {
		.contactInfo {
			display: none;
		}

		.newsletter {
			width: auto;
			float: none;
		}
	}

	.footerFinish {
		.slogan {
			display: none;
		}

		.map {
			float: none;
		}
	}

	.imageInfo {
		.infoLabel {
			right: -10px;
		}
	}

	.aboutInfo {
		max-width: none;
		border-bottom: 0;

		.info {
			margin-top: 30px;
		}
	}

	.aboutPage {
		.moreInfo {
			padding-left: 0;
		}
	}

	.terraGroup {
		.groupsList {
			padding-left: 0;

			&:before {
				display: none;
			}
		}

		.item {
			img {
				width: 200%;
				left: -100%;
			}
		}
	}

	.colorsList.count_6 {
		.colorItem {
			width: 20%;
		}
	}

	.categoriesBanners {
		height: 454px;

		> .axis {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.categoriesView {
		.collectionHead {
			.left {
				display: none;
			}

			.right {
				float: none;
				width: auto;
			}
		}
	}

	.textContent {
		img {
			max-width: 100%;
			height: auto;
		}
	}

	.productsList {
		.img {
			height: auto;
		}
	}

	.catProdList {
		margin-left: 0;
		margin-right: 0;

		.categoryItem {
			&:before {
				left: -20px;
				right: -20px;
			}
		}

		.catName {
			margin-left: 0;
			margin-right: 0;
		}
	}

	.productViewItem  {
		.productInfo,
		.productOtherInfo {
			width: auto;
			float: none;
		}

		.productOtherInfo {
			position: relative;

			.productDownloads {
				position: absolute;
				right: 0;
			}

			.fullImg {
				margin: 0;
			}

			.buyIt {
				padding-left: 0;
			}
		}
	}

	.searchForm  {
		.advanced {
			margin-left: 0;
			margin-right: 0;
		}
	}

	.searchResults {
		padding-left: 0;
		padding-right: 0;
	}

	.newsContent {
		img {
			max-width: 100%;
			height: auto;
		}

		.newsPageText {
			max-width: 200px;
		}
	}
}

@media all and (max-width: 719px) {
	.categoriesInLine {
		background: $color-base;

		&.count_7,
		&.count_8,
		&.count_9,
		&.count_10 {
			a {
				width: 25%;
				border-bottom: 1px solid #bde644;

				&:last-child {
					border-right: 1px solid #bde644;
				}
			}
		}
	}

	.inRow {
		&.count_4 {
			.item {
				width: 49%;
			}
		}
	}

	.mainLinks {
		.inRow {
			.item {
				width: 50%;
				margin-bottom: 30px;
			}
		}
	}

	.colorsList.count_6 {
		.colorItem {
			width: 25%;
		}
	}

	.contactCatalogue {
		max-width: none;
		margin-left: 0;
	}

	.categoriesBanners {
		.categoriesVList {
			width: 200px;
		}
	}

	.searchForm {
		.aRow.count_4 {
			.item {
				width: 50%;
			}
		}
	}

	.newsContent {
		.newsPageText {
			display: none;
		}
	}
}

@media all and (max-width: 600px) {
	.axis {
		padding-left: 10px;
		padding-right: 10px;
	}

	.newsSimpleItem {
		.newsItem {
			.img {
				width: auto;
				float: none;
				margin: 0 0 10px;
			}
		}
	}

	.footerBlocks {
		.contactData {
			margin-right: 0;
			min-height: 0;
		}

		.catalogue {
			position: static;
			margin-top: 20px;
		}
	}

	.footerFinish {
		margin-top: 20px;

		.map {
			.name {
				float: none;
				margin-bottom: 5px;
			}
		}
	}

	.imageInfo {
		.img {
			display: none;
		}
	}

	.terraGroup {
		.item {
			width: (100% / 3) !important;
			margin-bottom: 20px;
		}
	}

	.materialsIndex {
		.materialsList {
			margin: 0;
			width: auto;
		}
	}

	.materialsList {
		.img {
			margin-right: 15px;
		}
	}

	table.rwdTable {
		display: block;

		>, > tbody > {
			tr {
				display: block;

				> {
					td, th {
						margin-bottom: 20px;
						display: block;
						width: auto !important;
						padding: 0;
						overflow: hidden;
					}
				}
			}
		}
	}

	.rwdHide {
		display: none !important;
	}

	.contactPage {
		.info,
		.form {
			width: auto;
			float: none;
		}

		.form {
			margin-top: 40px;
		}
	}

	.categoriesView {
		.cRow {
			.left,
			.right {
				float: none;
				width: auto;
				padding: 0;
			}
		}

		.collectionHead {
			display: none;
		}
	}

	.brandsInfoList {
		.item {
			width: 100% !important;

			&.hasImage {
				padding-bottom: 0 !important;
			}

			.img {
				position: static;
			}
		}
	}

	.productsList.count_5 {
		.prodItem {
			width: 25%;
		}
	}

	.categoriesBanners {
		.bannerName {
			top: 5px;

			span {
				float: none;
				margin-bottom: 3px;
				text-align: right;
			}

			.pName {
				width: auto;
			}
		}
	}

	.productHead {
		.brand {
			float: none;
			width: auto;
			border-bottom: 1px solid $color-base;
			text-align: center;
		}
	}

	.productBottomInfo {
		.textInfo,
		.specificationInfo {
			width: auto;
			float: none;
		}

		.smallColors.count_3 {
			.item {
				width: 20%;
			}
		}
	}

	.searchForm {
		.aRow.count_3 {
			.item {
				width: 100%;
			}

			.subTitle {
				margin-top: 20px;
			}
		}

		.finishRow {
			.tags {
				float: none;
			}

			.niceInputs {
				.input {
					float: none;
					margin: 0 0 8px;
				}
			}
		}

		.submit {
			margin-top: 20px;

			.button {
				width: 100%;
				@include prefix(box-sizing, border-box);
			}
		}
	}
}

@media all and (max-width: 479px) {
	.categoriesInLine {
		&.count_7,
		&.count_8,
		&.count_9,
		&.count_10 {
			a {
				width: 50%;
			}
		}
	}

	.banners {
		.banner {
			.textContent {
				width: auto;
				right: 10px;
			}
		}
	}

	.layoutBanners {
		.arrows {
			width: auto;
			top: 245px;
			left: 10px;
		}
	}

	.inRow {
		.item {
			width: 100% !important;
		}
	}

	.terraGroup {
		.item {
			width: 50% !important;
		}
	}

	.colorsList.count_6 {
		.colorItem {
			width: (100% / 3);
		}
	}

	.contactCatalogue {
		.form {
			label {
				width: auto;
				float: none;
				margin-right: 0;
				text-align: left;
			}
		}
	}

	.categoriesBanners {
		.img {
			img {
				display: block;
				position: relative;
				left: -90px;
			}
		}
	}

	.productsList.count_5 {
		.prodItem {
			width: (100% / 3);
		}
	}

	.productBottomInfo {
		.smallColors.count_3 {
			.item {
				width: 25%;
			}
		}
	}

	.changeProduct{
		.prev,
		.next {
			width: 50px;
		}
	}

	.productViewItem {
		.productOtherInfo {
			.productDownloads {
				position: relative;
				margin-top: 0;
			}
		}
	}

	.searchForm {
		.aRow.count_4 {
			.item {
				width: 100%;
			}

			.subTitle {
				margin-top: 20px;
			}
		}
	}
}

@media all and (max-width: 360px) {
	.colorsList.count_6 {
		.colorItem {
			width: (100% / 2);
		}
	}

	.materialsList {
		.img {
			float: none;
			margin: 0 0 10px;
		}
	}

	.categoriesBanners {
		.categoriesVList {
			float: none;
			width: auto;
		}

		.img {
			display: none;
		}
	}

	.productsList.count_5 {
		.prodItem {
			width: 50%;
		}
	}

	.productBottomInfo {
		.smallColors.count_3 {
			.item {
				width: (100% / 3);
			}
		}
	}
}
